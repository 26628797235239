@import '~bootstrap/dist/css/bootstrap.css';
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap');

/* 
*
*
Application Colours Configurations 
*
*
*/

:root {
  --dark: rgba(0, 0, 0, 1);
  --grey: #505f79;
  --grey2: #a8afbc;
  --grey3: #e3e3e3;
  --grey4: #7a869a;
  --grey5: #061938;
  --secondaryTheme: #f7f7f8;
  --selectBackground: rgba(243, 245, 247, 1);
  --tasksText: #332620;
  --light: rgba(255, 255, 255, 1);
  --primaryTheme: #07588f;
  --appTheme: #ffffff;
}

/* 
*
*
Application Basic Configurations 
*
*
*/

body {
  margin: 0;
  font-family: 'Red Hat Text', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--appTheme) !important;
  letter-spacing: 0.03em;
}

body::-webkit-scrollbar {
  display: none;
}

/* 
*
*
Application Components 
*
*
*/

.PageContainer {
  padding: 28px 40px;
}

.divider {
  background: linear-gradient(
    to left,
    rgba(224, 87, 23, 0) 1.11%,
    var(--primaryTheme) 100%
  );
  height: 2px;
  width: 100%;
  opacity: 0.4;
}

.scroller-section {
  height: 360px;
  overflow-y: scroll;
}

.scroller-section::-webkit-scrollbar {
  width: 7px;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.scroller-section::-webkit-scrollbar-button {
  display: none;
}

.scroller-section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(27, 27, 27, 0.3);
}

/* 
*
*
Application Typography 
*
*
*/

/* 
*
*
white fonts
*
* 
*/

.font-11-white-thin {
  font-size: 11px;
  color: #fff;
  font-weight: 400;
}

.font-12-white-thin {
  font-size: 12px;
  color: #fff;
  font-weight: bold;
}

.font-12-white-normal {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}

.font-26-white-normal {
  font-size: 26px;
  color: #fff;
  font-weight: 600;
}

.font-40-white-normal {
  font-size: 40px;
  color: #fff;
  font-weight: 500;
}

/* 
*
*
black fonts
*
* 
*/

/* 14px black fonts */

.font-black-14-normal {
  font-size: 14px;
  color: var(--dark);
}

.font-black-14-bold {
  font-size: 14px;
  color: var(--dark);
  font-weight: bold;
  text-align: left;
}

.font-black-16-normal {
  font-size: 16px;
  color: var(--dark);
  font-weight: 600;
}

.font-black-16-thin {
  font-size: 16px;
  color: var(--dark);
  font-weight: 100;
}

.font-black-12-normal {
  font-size: 12px;
  color: var(--dark);
}

.font-black-12-bold {
  font-size: 12px;
  color: var(--dark);
  font-weight: bold;
}

.font-black-12-bolder {
  font-size: 12px;
  color: var(--dark);
  font-weight: bolder;
}

.font-black-14-normal {
  font-size: 14px;
  color: var(--dark);
}

.font-black-14-bold {
  font-size: 14px;
  color: var(--dark);
  font-weight: bold;
}

.font-black-16-normal {
  font-size: 16px;
  color: var(--dark);
}

.font-black-16-thin {
  font-size: 16px;
  color: var(--dark);
  font-weight: 100;
}

.font-black-16-bold {
  font-size: 16px;
  color: var(--dark);
  font-weight: bold;
  text-align: left !important;
}

/* 18px black fonts */

.font-black-18-normal {
  font-size: 18px;
  color: var(--dark);
}

.font-black-18-bold {
  font-size: 18px;
  color: var(--dark);
  font-weight: bold;
}

.font-black-40-bold {
  font-size: 40px;
  color: var(--dark);
  font-weight: bold;
}

/* 20px black fonts */

.font-black-20-bold {
  font-size: 20px;
  color: var(--dark);
  font-weight: bold;
}

/* 24px black fonts */

.font-black-24-bold {
  font-size: 24px;
  color: var(--dark);
  font-weight: 600;
}

.font-black-24-normal {
  font-size: 24px;
  color: var(--dark);
  font-weight: 500;
  text-align: left !important;
}

.font-black-24-superbold {
  font-size: 24px;
  color: var(--dark);
  font-weight: bolder;
  text-transform: capitalize;
}

/* 36px black fonts */

.font-black-36-bold {
  font-size: 36px;
  color: var(--dark);
  font-weight: bold;
}

/* 40px black fonts */

.font-black-40-bold {
  font-size: 40px;
  color: var(--dark);
  font-weight: bold;
}

/* 
*
*
Grey fonts
*
* 
*/

/* 12px grey fonts */

.font-grey-12-normal {
  font-size: 12px;
  color: var(--grey);
  text-align: left;
}

/* 14px grey fonts */

.font-grey-14-normal {
  font-size: 14px;
  color: var(--grey);
  text-align: left;
}

.font-grey-14-thin {
  font-size: 14px;
  color: var(--grey);
}

.font-grey-14-bold {
  font-size: 14px;
  color: var(--grey);
  font-weight: bold;
}

.font-grey-16-thin {
  font-size: 16px;
  color: var(--grey4);
  font-weight: 400;
}

.font-grey-18-bold {
  font-size: 18px;
  color: var(--grey4);
  font-weight: 600;
}

.font-grey-16-normal {
  font-size: 16px;
  color: var(--grey4);
  font-weight: 400;
}

.font-grey-20-normal {
  font-size: 20px;
  color: var(--grey);
  font-weight: 500;
}

.font-grey-20-bold {
  font-size: 20px;
  color: var(--grey);
  font-weight: 700;
}

/* 
*
*
Primary Fonts 
*
*
*/

.font-primary-14-bold {
  font-size: 14px;
  color: var(--primaryTheme);
  font-weight: 600;
}

.font-primary-16-bold {
  font-size: 16px;
  color: var(--primaryTheme);
  font-weight: 600;
}

.font-primary-16-thin {
  font-size: 16px;
  color: var(--primaryTheme);
}

.font-primary-20-bold {
  font-size: 20px;
  color: var(--primaryTheme);
  font-weight: bold;
}

/*
*
*
Buttons
*
*
*/

.primary-text-button {
  background: none;
  border: none;
  color: var(--primaryTheme);
}

.primary-contained-button {
  background: var(--primaryTheme);
  color: #fff;
  border: none;
}

.primary-outlined-button {
  background: none;
  border: 1px solid var(--primaryTheme);
  color: var(--primaryTheme);
}

.primary-grey-button {
  color: #fff;
  background-color: #4c4c4c;
  border: none;
}

.primary-white-button {
  background-color: white;
  color: black;
  border: none;
}

/*
*
*
Form Fields
*
*
*/

::placeholder {
  color: var(--grey2);
  opacity: 0.7;
  font-weight: 500;
}

select,
input {
  height: 50px;
  background-color: var(--selectBackground);
  color: var(--grey2);
  width: 100%;
  border: 0px;
}

select:focus,
input:focus {
  outline: none;
}

/*
*
Other Pages
*
*/

.Event-header {
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: cover;
  padding-left: 20%;
}

.Outer-Header-Container {
  position: sticky;
  top: 0;
  z-index: 9;
}

.FormContainer {
  padding: 30px 0px;
}

.upload-image-container {
  background-color: #f7f8fc;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
}

.dashed-border {
  border: 1px dashed var(--dark);
  cursor: pointer;
  border-radius: 10px;
}

.Events-container {
  overflow-x: scroll;
}

.Events-container::-webkit-scrollbar {
  height: 7px;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.enquirecard {
  background-color: rgba(245, 245, 245, 0.9);
  width: 550px;
  height: 640px;
  border-radius: 24px;
  padding: 70px 60px 70px 60px;
}

input[type='date'] {
  text-transform: uppercase !important;
}

.carousel-indicators {
  /* height: 10px !important; */
  /* width: 10px !important; */
  border-radius: 50%;
  background: #fff;
}

.menu-tabs {
  color: var(--tasksText);
  z-index: 99;
}

.menu-tabs:hover {
  color: var(--primaryTheme);
}
